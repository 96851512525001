









































import { Component, Vue } from 'vue-property-decorator';
import WorkspaceAvatar from "@/components/workspace/WorkspaceAvatar.vue";
import WorkspaceMemberItem from "@/components/navigation/WorkspaceMemberItem.vue";
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";
import PopItem from "@/components/general/poppers/PopItem.vue";
import WorkspaceSwitcher from "@/components/navigation/WorkspaceSwitcher.vue";
import UserSidebar from "@/components/navigation/UserSidebar.vue";
import WorkspaceSettingsIndex from "@/components/settings/workspace/WorkspaceSettingsIndex.vue";
import MemberListItem from "@/components/navigation/MemberListItem.vue";



@Component({
  components: {
    MemberListItem,
    UserSidebar,
    WorkspaceSwitcher,
    PopItem,
    WorkspaceMemberItem,
    WorkspaceAvatar,
    CreateWorkspaceInviteModal,
    WorkspaceSettingsIndex
  },
})
export default class WorkspaceMenu extends Vue {
  get workspace() {
    return this.$store.state.workspace;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get filterDeletedWorkspaceMember() {
    return this.workspace.members.filter((member: any) => {
      return member.user.deleted_at === null;
    })
  }

  get hasPinnedMessages(){
    return this.$store.state.workspace.pinnedMessages.length >= 1;
  }

  openUserInviteModal() {
    this.$buefy.modal.open({
      component: CreateWorkspaceInviteModal,
      props: {
        workspaceId: this.workspace!.id,
        workspacePid: this.workspace!.pid,
      },
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      width: '480px',
    });
  }

}
