




























import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PopItem extends Vue {
  isOpen: boolean = false;

  @Prop({ required: false, default: 'right-bottom' })
  position!: string;

  @Prop({ required: false, default: true })
  canClose!: boolean;

  @Prop({ required: false, default: false })
  paddingless!: boolean;

  @Prop({ required: false, default: true })
  hasBackground!: boolean;

  @Prop({ required: false, default: false })
  transparent!: boolean;

  @Prop({ required: false, default: true })
  isLight!: boolean;

  toggle() {
    this.isOpen = !this.isOpen;
    setTimeout(() => {
      this.correctPosition();
    }, 300);
  }

  close() {
    if(this.canClose) {
      this.isOpen = false;
    }
  }

  correctPosition() {
    const element = document.querySelector('.popup') as HTMLInputElement;
    if(element) {
      const rect = element.getBoundingClientRect();

      let clientHeight = document.documentElement.clientHeight;
      let clientWidth = document.documentElement.clientWidth;

      let rightClass = '';
      let topClass = '';
      let bottomClass = '';
      let leftClass = '';


      if(rect.top < 0) {
        topClass = ("margin-bottom: " + rect.top + 'px; ');
      }

      if(rect.left < 0) {
        leftClass = ("margin-right: " + rect.left + 'px; ');
      }

      /**
       * correcting for right side outside
       * */
      if((rect.left + rect.width) > clientWidth) {
        let offsetRight = (clientWidth - rect.left) - rect.width;
        rightClass = ("margin-left: " + offsetRight + 'px; ');
      }

      /**
       * correcting for bottom outside
       * */
      if((rect.top + rect.height) > clientHeight) {
        let offsetBottom = (clientHeight - rect.top) - rect.height;
        bottomClass = ("margin-top: " + offsetBottom + 'px; ');
      }

      /**
       * finallly apply all styles
       * */
      element.setAttribute("style", '' + rightClass + topClass + bottomClass + leftClass);
    }
  }
}
